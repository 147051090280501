import { CToast, CToastBody, CToastHeader } from '@coreui/react';
import React from 'react';
import { EventEmitter } from 'events';
import { v4 as uuidv4 } from 'uuid';

const Constants = {
  CHANGE: 'change',
  INFO: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'danger',
};

class ToasterManager extends EventEmitter {
  constructor() {
    super();
    this.toasters = [];
  }
  pushToast(duration, message, title, type) {
    const id = uuidv4();

    const toast = {
      toast: (
        <CToast show={true} autohide={duration} onStateChange={(evt) => this.removeToast(evt, id)} color={type}>
          <CToastHeader closeButton={true}>
            <strong className='me-auto text-white'>{title}</strong>
          </CToastHeader>
          <CToastBody className='text-white'>{message}</CToastBody>
        </CToast>
      ),
      id,
    };
    this.toasters.push(toast);

    this.emitChange();
  }
  removeToast(evt, id) {
    if (!evt) {
      this.toasters = this.toasters.filter((item) => item.id != id);
      this.emitChange();
    }
  }
  emitChange() {
    this.emit(Constants.CHANGE, this.toasters);
  }
  addChangeListener(callback) {
    this.addListener(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
  info(message, duration = 3000, title = 'Info') {
    this.pushToast(duration, message, title, Constants.INFO);
  }
  success(message, duration = 3000, title = 'Success') {
    this.pushToast(duration, message, title, Constants.SUCCESS);
  }

  error(message, duration = 3000, title = 'Error') {
    this.pushToast(duration, message, title, Constants.ERROR);
  }
  warn(message, duration = 3000, title = 'Warning') {
    this.pushToast(duration, message, title, Constants.WARNING);
  }
}
export const toastManager = new ToasterManager();
