import { CToaster } from '@coreui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { toastManager } from './toaster-manager';

export default function ToasterContainer() {
  const [toasters, setToasters] = useState([]);

  useEffect(() => {
    toastManager.addChangeListener(toasterHandler);
    return () => {
      toastManager.removeChangeListener(toasterHandler);
    };
  }, []);

  function toasterHandler(toasts) {
    setToasters([...toasts]);
  }

  return (
    <CToaster className='safe-area-pad'>
      {toasters.map((toastInfo) => (
        <Fragment key={toastInfo.id}>{toastInfo.toast}</Fragment>
      ))}
    </CToaster>
  );
}
