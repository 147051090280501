import { default as jwtDecode } from "jwt-decode";
import restActions from "src/actions/Rest";
import { roles } from "src/helper/role";
import { Device } from "@awesome-cordova-plugins/device";
import { Capacitor } from '@capacitor/core';

export default function getUser() {
  const token = localStorage.getItem("token");
  return token ? jwtDecode(token) : null;
}

export const checkUserValidity = (user) => {
  const userData = user ?? getUser();
  if (
    !userData?.email ||
    !userData?.name ||
    !userData?.contact ||
    !userData?.isContactVerified
  ) {
    return false;
  }

  return true;
};

export const isUserValid = async () => {
  const userInfo = await restActions.GET(`/user/${getUser()?.userId}`);

  return checkUserValidity(userInfo?.data?.user);
};

// Get User role
export function isAdminOrSuperAdmin() {
  const user = getUser();
  console.log("user", user);
  return (
    user &&
    user.currentRole &&
    (user.currentRole === roles.ADMIN || user.currentRole === roles.SUPER_ADMIN)
  );
}

export const isUserOperator = () => {
  const user = getUser();
  return user && user.currentRole && user.currentRole === roles.OPERATOR;
};

export const isUserMerchant = () => {
  const user = getUser();
  return user && user.currentRole && user.currentRole === roles.MERCHANT;
};

export const isUserOperatorOrMerchant = () => {
  const user = getUser();
  return (
    user &&
    user.currentRole &&
    (user.currentRole === roles.MERCHANT || user.currentRole === roles.OPERATOR)
  );
};

export const getDeviceId = () => {
  console.log("getDeviceId", Capacitor.getPlatform())
  if(Capacitor.getPlatform() !== 'web')
  return Device.uuid;
  else
  return;
}