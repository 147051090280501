export const constants = {
  INCENTIVE_REALTIME: 34,
  INCENTIVE_CUMULATIVE: 33,

  SMART_BIN_DASHBOARD: 11,
  SMART_BIN_USAGE: 14,
  SMART_BIN_REDEMATIONS: 39,
  LOGISTICS_KG_CUSTOM: 16,
  LOGISTICS_KG_DAILY: 55,
  LOGISTICS_KG_MONTHLY: 37,
  LOGISTICS_KG_WEEKLY: 39,
  LOGISTICS_KG_YEARLY: 40,
  LOGISTICS_KG_QUARTERLY: 35,

  LOGISTICS_KG_DAILY_WITH_BIN_NAME: 59,
  LOGISTICS_KG_WEEKLY_WITH_BIN_NAME: 60,
  LOGISTICS_KG_MONTHLY_WITH_BIN_NAME: 58,
  LOGISTICS_KG_QUARTERLY_WITH_BIN_NAME: 61,
  LOGISTICS_KG_YEARLY_WITH_BIN_NAME: 62,

  LOGISTICS_KG_YEARLY_WITH_DATE_AND_BIN: 119,
  LOGISTICS_KG_QUARTERLY_WITH_DATE_AND_BIN: 121,
  LOGISTICS_KG_MONTHLY_WITH_DATE_AND_BIN: 122,
  LOGISTICS_KG_WEEKLY_WITH_DATE_AND_BIN: 123,
  LOGISTICS_KG_DAILY_WITH_DATE_AND_BIN: 124,

  LOGISTICS_KG_YEARLY_WITH_DATE_ONLY: 126,
  LOGISTICS_KG_QUARTERLY_WITH_DATE_ONLY: 127,
  LOGISTICS_KG_MONTHLY_WITH_DATE_ONLY: 125,
  LOGISTICS_KG_WEEKLY_WITH_DATE_ONLY: 128,
  LOGISTICS_KG_DAILY_WITH_DATE_ONLY: 129,

  SESSION_COLLECTION_CUSTOM: 21,
  SESSION_COLLECTION_DAILY: 20,
  SESSION_COLLECTION_MONTHLY: 22,
  SESSION_COLLECTION_WEEKLY: 24,
  SESSION_COLLECTION_YEARLY: 25,
  SESSION_COLLECTION_QUARTERLY: 23,

  MONTHLY_WEIGHT_REPORT: 69,
  MONTHLY_WEIGHT_REPORT_FOR_SMARTBIN: 71,
  MONTHLY_WEIGHT_REPORT_FOR_WASTE: 72,
  MONTHLY_WEIGHT_REPORT_FOR_SMARTBIN_AND_WASTE: 73,

  DAILY_WEIGHT_REPORT: 70,
  DAILY_WEIGHT_REPORT_FOR_SMARTBIN: 74,
  DAILY_WEIGHT_REPORT_FOR_WASTE: 75,
  DAILY_WEIGHT_REPORT_FOR_SMARTBIN_AND_WASTE: 76,

  DAILY_USAGE_REPORT: 94,
  DAILY_USAGE_REPORT_FOR_SMARTBIN: 96,
  DAILY_USAGE_TYPE_REPORT_FOR_SMARTBIN: 96,

  MONTHLY_USAGE_REPORT: 93,
  MONTHLY_USAGE_REPORT_FOR_SMARTBIN: 95,
  MONTHLY_USAGE_TYPE_REPORT_FOR_SMARTBIN: 96,

  YOUR_SMART_BIN: 4,

  COUNTRIES: [
    { flag: "flag-ue", name: "UAE", currency: "AED" },
    { flag: "flag-sa", name: "Saudi", currency: "SAR" },
  ],
  LANGUAGE_CODE: {
    NORSK: "no",
    ARABIC: "ar",
    ENGLISH: "en",
  },
  EVENT_NAME: {
    LOGIN: 'LOGIN',
    PWA_INSTALLATION: 'PWA_INSTALLATION',
    TIME_VISITED: 'TIME_VISITED',
    LOGIN_URL: 'LOGIN_URL',
    SCANNER_PARAMS: 'SCANNER_PARAMS',
    VOUCHER_SKOUT: 'VOUCHER_SKOUT',
    GAM_TXFER_TO_FRIEND: 'GAM_TXFER_TO_FRIEND'
  },
  LOGIN_TYPE: {
    EMAIL: "email",
    PHONE: "phone",
  },
  BIN_WEIGHT: {
    BELOW_THRESHOLD_INCENTIVES_DIVIDEND: 20,
    ABOVE_THRESHOLD_INCENTIVES_DIVIDEND: 15,
    THRESOLD: 50,
  },
  INCENTIVES_DIVIDEND: 20,
  ENABLED_FEATURE:{
    GAM_TXFER_TO_FRIEND : 'GAM-TXFER-TO-FRIEND',
    GAM_RANKINGS: 'GAM-RANKINGS',
    REDEEM_DISCOUNTED : 'REDEEM_DISCOUNTED',
    REDEEM_DIRECT : 'REDEEM_DIRECT',
    REDEEM_LUCKY_DIP : 'REDEEM_LUCKY_DIP',
    REDEEM_DONATION : 'REDEEM_DONATION',
  }
};
